<template>
  <div
    v-if="!isTutorialOpened && opened"
    class="relative hidden min-h-[50px] w-44 items-center gap-4 hover:max-h-max md:flex"
  >
    <router-link
      to="/tutorial/basics"
      class="group/tutorial absolute top-0 z-10 flex min-h-full w-full cursor-pointer rounded-2xl border border-solid border-[#33FF991A] bg-[#14281e] px-3 !opacity-100 focus-within:border-[#202020] focus-within:bg-[#191919] md:py-0 [&>div>a]:!px-1 [&>div>a]:md:!px-4"
    >
      <div class="flex w-full items-center justify-between gap-2 px-1 py-2">
        <div class="flex flex-col items-start">
          <span class="text-base font-medium leading-none">
            <span class="larken text-[#33FF99]"> New here? </span>
            <!-- <span class="larken hidden leading-tight text-white group-focus-within/tutorial:block">
              Have you used a decentralized exchange before?
            </span> -->
          </span>
          <span class="text-[10px] leading-none text-[#33FF99]/50">Start tutorial!</span>
        </div>
        <button class="rounded-lg bg-[#33FF99]/10 p-1.5" @click="handleClose">
          <Icon class="text-xs text-[#33FF99]/50" icon="humbleicons:times" />
        </button>
        <!-- <div class="hidden w-full flex-col gap-2.5 group-focus-within/tutorial:flex">
          <button
            class="grid w-full grid-cols-[auto_1fr] items-center gap-x-2 rounded-[14px] border border-solid border-[#33FF991A] bg-[#14281e] px-3 py-2.5 text-left text-white"
            @click="handleClose"
          >
            <Icon class="row-span-2 text-2xl text-[#33FF99]" icon="ic:round-check" />
            <span class="text-xs">Yes</span>
            <span class="text-[10px] leading-none">Use Dexes often</span>
          </button>
          <router-link
            to="/tutorial/basics"
            class="grid w-full grid-cols-[auto_1fr] items-center gap-x-2 rounded-[14px] border border-solid border-[#E841421A] bg-[#271616] px-3 py-2.5 text-left text-white"
          >
            <Icon class="row-span-2 text-2xl text-[#E84142]" icon="humbleicons:times" />
            <span class="text-xs">No</span>
            <span class="text-[10px] leading-none">First time here</span>
          </router-link>
        </div> -->
      </div>
    </router-link>
  </div>
  <div v-else-if="isTutorialOpened" class="flex rounded-2xl bg-[#191919]">
    <MenuLink title="Onboarding" class="pointer-events-none [&>*]:!text-white" />
    <div class="mx-auto my-2 hidden min-h-full border-r border-solid border-white/5 md:flex" />
    <MenuLink title="Basics" link-to="/tutorial/basics" class="hidden md:flex" />
    <MenuLink title="El Dorado" link-to="/tutorial/eldorado" class="hidden md:flex" />
    <MenuLink title="Country Club" link-to="/tutorial/ccc" class="hidden md:flex" />
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue/dist/iconify.js'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'
import MenuLink from './MenuLink.vue'

const opened = ref(true)
const handleClose = (e: MouseEvent) => {
  e.preventDefault()
  opened.value = false
}

const route = useRoute()

const isTutorialOpened = computed(
  () => route.path.startsWith('tutorial') || route.path.startsWith(`/tutorial`),
)
</script>
